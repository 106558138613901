
import { Component, Vue } from 'vue-property-decorator';
import {StoreMetadataList} from "@/collections/store_metadatas";
import {get} from "@/services/http";

@Component({
  components: {
  },
})
export default class StoreMetadataPage extends Vue {
  public isLoading: boolean = true;
  public storeMetadataList: StoreMetadataList = new StoreMetadataList();
  public currentPage: number = 1;
  public perPage: number = 30;
  public lastPage: number = 1;
  public keyword: string = '';
  public subType: string = '';
  public status: string = '';
  public tag: string = '';
  public tags: string[] = [];

  public async created() {
    await this.fetchData();

    const res: any = await get('/store_metadata/tags');
    for (const item of res.data) {
      this.tags.push(item['value']);
    }
  }

  public async fetchData() {
    this.isLoading = true;
    this.storeMetadataList = new StoreMetadataList();
    await this.storeMetadataList.fetch(
        this.currentPage,
        this.perPage,
        {
          keyword: this.keyword,
          status: this.status,
          subType: this.subType,
          tag: this.tag,
        },
    );
    this.lastPage = this.storeMetadataList.lastPage;
    this.isLoading = false;
  }

  public clearFiler() {
    this.keyword = '';
    this.currentPage = 1;
    this.fetchData();
  }

  public convertDateStr(dateStr: string) {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    const timezoneOffset = 7 * 60;
    const adjustedDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);
    const padZero = (num: number) => (num < 10 ? '0' : '') + num;
    return `${adjustedDate.getUTCFullYear()}-${padZero(adjustedDate.getUTCMonth() + 1)}-${padZero(adjustedDate.getUTCDate())} ${padZero(adjustedDate.getUTCHours())}:${padZero(adjustedDate.getUTCMinutes())}:${padZero(adjustedDate.getUTCSeconds())}`;
  }

  public colorStatus(status: string) {
    if (status === 'UNINSTALLED') {
      return 'red';
    } else if (status === 'INSTALLED') {
      return 'green';
    } else if (status === 'DOWNGRADED') {
      return 'grey';
    }
    return 'orange';
  }
}
