import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import SignInPage from '@/views/SignInPage.vue';
import TemplateReports from '@/views/TemplateReports.vue';
import TemplateReportSortPage from '@/views/TemplateReportSortPage.vue';
import TemplateReportNewPage from '@/views/TemplateReportNewPage.vue';
import TemplateReportEditPage from '@/views/TemplateReportEditPage.vue';
import Faqs from '@/views/Faqs.vue';
import FaqNewPage from '@/views/FaqNewPage.vue';
import FaqEditPage from '@/views/FaqEditPage.vue';
import Tags from '@/views/Tags.vue';
import TagNewPage from '@/views/TagNewPage.vue';
import TagEditPage from '@/views/TagEditPage.vue';
import TagTypes from '@/views/TagTypes.vue';
import TagTypeNewPage from '@/views/TagTypeNewPage.vue';
import TagTypeEditPage from '@/views/TagTypeEditPage.vue';
import Permissions from '@/views/Permissions.vue';
import PermissionEditPage from '@/views/PermissionEditPage.vue';
import Stores from '@/views/Stores.vue';
import PaidStores from '@/views/PaidStores.vue';
import PassedTrialStores from '@/views/PassedTrialStores.vue';
import ActiveTrialStores from '@/views/ActiveTrialStores.vue';
import Store from '@/views/Store.vue';
import DefaultMetrics from '@/views/DefaultMetrics.vue';
import DefaultMetricNewPage from '@/views/DefaultMetricNewPage.vue';
import BillingPackages from '@/views/BillingPackages.vue';
import BillingPackage from '@/views/BillingPackage.vue';
import BillingPackageNewPage from '@/views/BillingPackageNewPage.vue';
import Rules from '@/views/Rules.vue';
import RuleNewPage from '@/views/RuleNewPage.vue';
import RuleEditPage from '@/views/RuleEditPage.vue';
import UserDashboards from '@/views/UserDashboards.vue';
import CustomReports from '@/views/CustomReports.vue';
import Discounts from '@/views/Discounts.vue';
import DiscountNewPage from '@/views/DiscountNewPage.vue';
import DiscountEditPage from '@/views/DiscountEditPage.vue';
import PluginList from '@/views/Plugins.vue';
import ControlPanel from '@/views/ControlPanel.vue';
import PluginEdit from '@/views/PluginEdit.vue';
import ResourcePage from '@/views/ResourcePage.vue';
import ResourceNewPage from '@/views/ResourceNewPage.vue';
import StoreMetadataPage from '@/views/StoreMetadataPage.vue';
import StoreMetadataDetailPage from '@/views/StoreMetadataDetailPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInPage,
  },
  {
    path: '/template_reports',
    name: 'template_reports',
    component: TemplateReports,
  },
  {
    path: '/template_reports/sort',
    name: 'template_report_sort',
    component: TemplateReportSortPage,
  },
  {
    path: '/template_reports/new',
    name: 'template_reports_new',
    component: TemplateReportEditPage,
  },
  {
    path: '/template_reports/:id',
    name: 'template_reports_edit',
    component: TemplateReportEditPage,
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs,
  },
  {
    path: '/faqs/new',
    name: 'faqs_new',
    component: FaqNewPage,
  },
  {
    path: '/faqs/:id',
    name: 'faqs_edit',
    component: FaqEditPage,
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tags,
  },
  {
    path: '/tags/new',
    name: 'tags_new',
    component: TagNewPage,
  },
  {
    path: '/tags/:id',
    name: 'tags_edit',
    component: TagEditPage,
  },
  {
    path: '/tag_types',
    name: 'tag_types',
    component: TagTypes,
  },
  {
    path: '/tag_types/new',
    name: 'tag_types_new',
    component: TagTypeNewPage,
  },
  {
    path: '/tag_types/:id',
    name: 'tag_types_edit',
    component: TagTypeEditPage,
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: Permissions,
  },
  {
    path: '/permissions/:id',
    name: 'permission_edit',
    component: PermissionEditPage,
  },
  {
    path: '/stores',
    name: 'store_list',
    component: Stores,
  },
  {
    path: '/paid_stores',
    name: 'paid_store_list',
    component: PaidStores,
  },
  {
    path: '/passed_trial_stores',
    name: 'passed_trial_store_list',
    component: PassedTrialStores,
  },
  {
    path: '/active_trial_stores',
    name: 'active_trial_store_list',
    component: ActiveTrialStores,
  },
  {
    path: '/stores/:id',
    name: 'store',
    component: Store,
  },
  {
    path: '/default-metrics',
    name: 'default_metrics_list',
    component: DefaultMetrics,
  },
  {
    path: '/default-metrics/new',
    name: 'default_metrics_new',
    component: DefaultMetricNewPage,
  },
  {
    path: '/billing_packages',
    name: 'billing_packages_list',
    component: BillingPackages,
  },
  {
    path: '/billing_packages/new',
    name: 'billing_packages_new',
    component: BillingPackageNewPage,
  },
  {
    path: '/billing_packages/:id',
    name: 'billing_package',
    component: BillingPackage,
  },
  {
    path: '/rules',
    name: 'rules_list',
    component: Rules,
  },
  {
    path: '/rules/new',
    name: 'rules_new',
    component: RuleNewPage,
  },
  {
    path: '/rules/:id',
    name: 'rules_edit',
    component: RuleEditPage,
  },
  {
    path: '/user_dashboards',
    name: 'userDashboards',
    component: UserDashboards,
  },
  {
    path: '/custom_reports',
    name: 'customReports',
    component: CustomReports,
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: Discounts,
  },
  {
    path: '/discounts/new',
    name: 'DiscountNewPage',
    component: DiscountNewPage,
  },
  {
    path: '/discounts/:id',
    name: 'DiscountEditPage',
    component: DiscountEditPage,
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: ControlPanel,
  },
  {
    path: '/plugins',
    name: 'pluginList',
    component: PluginList,
  },
  {
    path: '/plugin/new',
    name: 'plugin_new',
    component: PluginEdit,
  },
  {
    path: '/plugin/edit/:id',
    name: 'plugin_edit',
    component: PluginEdit,
  },
  {
    path: '/resource',
    name: 'resourcePage',
    component: ResourcePage,
  },
  {
    path: '/resource/new',
    name: 'resource_new',
    component: ResourceNewPage,
  },
  {
    path: '/resource/edit/:id',
    name: 'resource_edit',
    component: ResourceNewPage,
  },
  {
    path: '/store_metadata',
    name: 'storeMetadataPage',
    component: StoreMetadataPage,
  },
  {
    path: '/store_metadata/:id',
    name: 'storeMetadataDetailPage',
    component: StoreMetadataDetailPage,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
