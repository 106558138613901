
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MeasureList } from '@/collections/measures';
import { del } from '@/services/http';

@Component
export default class ControlPanel extends Vue {
  public measures: MeasureList = new MeasureList();
  public loading: boolean = false;
  public loadingClearCacheQuestion: boolean = false;

  public async clearCacheMeasures() {
    this.loading = true;
    await this.measures.clearCache();
    this.loading = false;
  }

  public async clearCacheQuestions () {
    this.loadingClearCacheQuestion = true;

    // del cached_resources
    const res = await del('/cached_resources', {
      resource: 'question_'
    });

    this.loadingClearCacheQuestion = false;
  }

}
