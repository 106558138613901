import { get, post } from '@/services/http';
import { DimensionModel } from '@/models/dimension';
import List from './list';

export class DimensionList extends List<DimensionModel> {
  public async fetch() {
    const res: any = await get(`/dimensions`, {});
    const dimensions = res.data || [];
    this.mapData(dimensions);
  }

  public mapData(data: any = []) {
    for (const s of data) {
      const dimension = new DimensionModel();
      dimension.mapData(s);
      this.add(dimension);
    }
  }
}
